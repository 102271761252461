@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

* {
  font-family: Rubik !important;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f0f0f0;
}

::-webkit-scrollbar-thumb {
  background-color: #1b252e;
  border-radius: 20px;
  border: 3px solid #f0f0f0;
}

.MuiOutlinedInput-root.Mui-focused fieldset {
  border-color: #212121 !important;
}
