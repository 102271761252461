.container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
  padding: 2rem 8rem 0.2rem 2rem;
  max-width: 30% !important;
  margin: 7rem 0;
}

.checkboxLabel {
  color: black;
}

.submitButton {
  background-color: #1b252e !important;
  color: white !important;
  margin: 1rem 0 !important;
}

.submitButton:hover {
  background-color: #efc734 !important;
  scale: 1.01;
}

.link {
  color: black !important;
}

@media only screen and (max-width: 600px) {
  .container {
    max-width: 90% !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .container {
    max-width: 50% !important;
  }
}
