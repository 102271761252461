.preFooter {
  text-align: center;
  padding: 2rem 0;
  background-color: #1b252e;
}

.preFooterHeading {
  font-weight: bold;
  color: white;
}

.preFooterText {
  margin-bottom: 2rem;
}

.subscribeButton {
  background-color: #efc734 !important;
  border-radius: 10px;
}

.footer {
  padding: 2rem;
  background-color: #1b252e;
  color: white;
  font-size: 0.8rem;
}

.footerList {
  list-style: none;
  padding-left: 0;
}

.footerList li {
  padding: 0.25rem 0;
}

.bottomBar {
  background-color: #1b252e;
  color: white;
  padding: 1rem 0;
  text-align: center;
}

.copyRightText {
  opacity: 0.7;
}

/* New Media Query for Mobile Devices */
@media (max-width: 600px) {
  .preFooter,
  .footer {
    display: none !important;
  }

  .bottomBar {
    position: relative !important;
    width: 100% !important;
    bottom: 0 !important;
  }
}
