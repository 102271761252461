.flexContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.flexItem {
  flex: 1;
}

.navListItem {
  margin: 1rem 0;
  font-weight: bold;
  position: relative;
  overflow: hidden;
}

.navListItem :hover {
  scale: 1.1;
}

.leftColumn {
  margin: 2rem !important;
  min-width: 25% !important;
  max-width: 25%;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  max-width: 75%;
  justify-content: center;
  align-items: center;
}

.profileCard {
  border-radius: 16px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.profileCardContent,
.profileCardInfo {
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  padding-bottom: 0;
  background-color: #f3f3f7;
}

.profilePicture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
}

.navList {
  width: 100%;
}

.textButton {
  text-transform: none;
  margin-right: 8px;
}

.notificationsButton {
  background-color: #040225 !important;
  color: white !important;
  padding: 0.7rem !important;
  border-radius: 25px !important;
  margin: 0 0.5rem !important;
}

/* Media queries for responsiveness */
@media only screen and (max-width: 1023px) {
  .leftColumn,
  .rightColumn {
    max-width: 100%;
  }

  .flexContainer {
    flex-direction: column !important;
  }
  .rightColumn {
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
}
