/* reviews.module.css */
.container {
  padding: 4em 12em !important;
}

.card {
  max-width: 300px;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  animation: fadeIn 1.5s ease-out !important;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card:hover .avatar {
  transform: scale(1.1);
}

.rating {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.star {
  color: #efc734;
  margin-bottom: 0.4rem;
  animation: pulse 2s infinite;
}

.userInfo {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.avatar {
  margin-right: 0.5rem;
  transition: transform 0.3s ease-in-out;
  transform-origin: center;
}

.comment {
  font-size: 0.9rem !important;
  color: #666;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media only screen and (min-width: 1200px) {
  .ratingsContainer .MuiGrid-item {
    flex-basis: 20%; /* or whatever percentage fits your design */
    max-width: 20%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .ratingsContainer .MuiGrid-item {
    flex-basis: 33.3333%;
    max-width: 33.3333%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .ratingsContainer .MuiGrid-item {
    flex-basis: 50%;
    max-width: 50%;
  }
  .container {
    padding: 3rem 2rem !important;
  }
}

@media only screen and (max-width: 600px) {
  .ratingsContainer .MuiGrid-item {
    flex-basis: 90%;
    max-width: 90%;
  }
  .container {
    padding: 3rem 2rem !important;
  }
}
