@import url('https://fonts.googleapis.com/css2?family=Rubik+Doodle+Shadow&family=Sen&display=swap');

.appBar {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(27, 37, 46, 1) 35%,
    rgba(64, 163, 209, 1) 100%
  );
  padding: 6em 12em !important;
  box-shadow: none !important;
}

.toolbar {
  padding: 1.5rem 6rem;
}

.greeting {
  font-weight: 400;
  margin: 0.8rem 0;
  text-align: left;
}

.searchContainer {
  display: flex;
  justify-content: space-between;
  background-color: #f3f3f3;
  border-radius: 25px !important;
  margin: 0 auto;
  padding: 1rem !important;
  width: 80%;
}

.inputBase {
  color: inherit;
  width: 80%;
}

.button {
  background-color: #1b252e !important;
  color: white;
  text-transform: none;
  border-radius: 20px !important;
}

.slogan {
  font-family: Rubik Doodle shadow !important;
}

@media only screen and (max-width: 767px) {
  .appBar {
    padding: 2em !important;
  }
  .toolbar {
    padding: 0.5rem 1rem;
  }
  .searchContainer {
    flex-direction: column;
    align-items: center;
    padding: 1rem !important;
    width: 100%;
    margin-bottom: 3rem;
  }
  .inputBase {
    width: 100%;
    margin-bottom: 1rem;
    max-height: 2vh !important;
  }
  .button {
    width: 100%;
    margin: 1rem 0;
  }
  .slogan,
  .greeting {
    text-align: center;
  }

  .searchContainer {
    width: 100% !important;
    max-height: 2vh !important;
  }
  .searchContainer {
    height: 4rem !important;
    width: 65% !important;
  }

  .sloganContainer {
    padding: 0 !important;
  }

  .slogan {
    font-size: 2rem !important;
  }
  .greeting {
    font-size: 1rem !important;
    margin: 1rem 0 !important;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 768px) {
  .appBar {
    padding: 4em 6em !important;
  }
  .toolbar {
    padding: 1rem 3rem;
  }
  .searchContainer {
    width: 90%;
  }
}

@media only screen and (min-width: 1200px) {
  .appBar {
    padding: 6em 12em !important;
  }
  .toolbar {
    padding: 1.5rem 6rem;
  }
  .searchContainer {
    width: 80%;
  }
}
