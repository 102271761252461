.headerContainer {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(27, 37, 46, 1) 35%,
    rgba(64, 163, 209, 1) 100%
  );
  padding: 6em 12em;
}

.headerTextContainer {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  animation: fadeIn 1s ease-out forwards;
}

.headerTitle {
  font-weight: bold;
  margin-bottom: 1em;
  color: white;
  line-height: 1.6em !important;
  animation: fadeIn 1.5s ease-out forwards;
}

.headerSubtitle {
  color: white;
  line-height: 2em !important;
  animation: fadeIn 2s ease-out forwards;
}

.headerImagesContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.headerImage {
  width: 100%;
  height: auto;
  border-radius: 25px;
  object-fit: cover;
}

.statsContainer {
  text-align: center;
  padding: 60px 20px;
  margin-left: -75px;
}

.statsItem {
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
  animation: fadeIn 2.3s ease-out forwards;
}

.statsNumber {
  font-weight: bold;
  color: #efc634;
  margin-bottom: 8px;
}

.statsText {
  color: white;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

.animateText {
  display: inline;
  animation: fadeInOut 2s ease-in-out infinite;
  color: #efc734;
}
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/*tablet screens*/

@media screen and (min-width: 769px) and (max-width: 1024px) {
}

/* Mobile screens */
@media screen and (max-width: 768px) {
  .headerContainer {
    padding: 1em 2em;
  }

  .headerTitle {
    font-size: 2rem !important;
  }

  .headerImagesContainer {
    display: none !important;
  }
  .statsText {
    font-size: 0.6rem !important;
  }
  .statsContainer {
    margin: 0;
  }
}
