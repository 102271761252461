.container {
  padding: 4em 12em !important;
  background-color: #3e9ecb19;
  animation: fadeIn 1s ease-out forwards;
}

.icon {
  font-size: 2rem !important;
  color: #3e9cc8;
}

.innerBox {
  background-color: transparent !important;
  transition: transform 1s ease-in-out;
}

.innerBox:hover {
  transform: scale(1.01);
}

@keyframes floatIcons {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.icon {
  display: inline-block;
  animation: floatIcons 3s ease-in-out infinite;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@media only screen and (max-width: 600px) {
  .assurance .MuiGrid-item {
    flex-basis: 100%;
    max-width: 100%;
  }

  .container {
    padding: 3rem 2rem !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .assurance .MuiGrid-item {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .assurance .MuiGrid-item {
    flex-basis: 50%;
    max-width: 50%;
  }
}

@media only screen and (min-width: 1200px) {
  .assurance .MuiGrid-item {
    flex-basis: 25%;
    max-width: 25%;
  }
}
