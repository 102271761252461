@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

.rateTaskContainer {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 4rem;
}

.rateTaskBox {
  background-color: #f9f3ef;
  padding: 5% !important;
  border-radius: 25px !important;
  width: 100% !important;
  max-width: 700px !important;
}

.rateTaskTitle {
  font-size: 2rem;
  color: #333;
  font-family: Anton, sans-serif !important;
  text-align: center;
  margin-bottom: 1rem;
}

.rateTaskSubtitle {
  font-size: 1rem !important;
  color: #666 !important;
  text-align: center !important;
  margin-bottom: 2rem;
}

.rateTaskCard {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px !important;
  padding: 2rem !important;
}

.rateTaskStatus {
  font-size: 14px !important;
  font-weight: bold !important;
  color: #757575 !important;
  margin-bottom: 0.5rem !important;
}

.rateTaskTitleText {
  font-size: 1.25rem !important;
  font-weight: bold !important;
  margin-bottom: 0.5rem !important;
  color: #333 !important;
}

.rateTaskDetails {
  margin-bottom: 1rem !important;
  color: #757575 !important;
  font-size: 0.9rem !important;
}

.rateTaskInfo {
  display: flex !important;
  align-items: center !important;
  margin-bottom: 1rem;
}

.rateTaskLabel {
  font-size: 0.8rem !important;
  color: #757575;
}

.rateTaskValue {
  font-size: 0.9rem !important;
  font-weight: bold;
  color: #333;
}

.rateTaskDivider {
  width: 100% !important;
  background-color: #1b252e;
  margin-bottom: 1rem !important;
}

.rateTaskFooter {
  display: flex;
  flex-direction: column !important;
  gap: 1rem;
}

.rateTaskRating {
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

.rateTaskStarIcon {
  color: #faaf00 !important;
}

.rateTaskRatingBox {
  display: flex;
  align-items: center !important;
  justify-content: space-between !important;
  padding: 1rem;
  border-radius: 8px;
}

.rateTaskButton {
  background-color: #3e9fcc;
  color: white;
  margin-left: 0.5rem !important;
}

.taskerImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
}

/* Media Queries */
@media (max-width: 1024px) {
  .rateTaskBox {
    padding: 5%;
  }

  .rateTaskTitle {
    font-size: 1.8rem;
  }

  .rateTaskSubtitle {
    font-size: 0.9rem;
  }

  .rateTaskCard {
    padding: 1.5rem;
  }
}

@media (max-width: 768px) {
  .rateTaskBox {
    padding: 5%;
  }

  .rateTaskTitle {
    font-size: 1.5rem;
  }

  .rateTaskSubtitle {
    font-size: 0.8rem;
  }

  .rateTaskCard {
    padding: 1.2rem;
  }

  .taskerImage {
    width: 40px;
    height: 40px;
  }
}

@media (max-width: 480px) {
  .rateTaskBox {
    padding: 5%;
  }

  .rateTaskTitle {
    font-size: 1.2rem;
  }

  .rateTaskSubtitle {
    font-size: 0.7rem;
  }

  .rateTaskCard {
    padding: 1rem;
  }

  .taskerImage {
    width: 30px;
    height: 30px;
  }
}
