.servicesContainer {
  background-color: #ffffff;
  padding: 6em 12em 2em !important;
  animation: fadeIn 1s ease-out forwards !important;
}

.taskIcon {
  font-size: 3rem !important;
  color: #3e9cc8;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadeIn {
  animation: fadeIn 1s ease-out forwards;
}

@media only screen and (min-width: 1200px) {
  .servicesContainer .MuiGrid-item {
    flex-basis: 20%;
    max-width: 20%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .servicesContainer .MuiGrid-item {
    flex-basis: 25%;
    max-width: 25%;
  }
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
  .servicesContainer .MuiGrid-item {
    flex-basis: 50%;
    max-width: 50%;
  }
  .servicesContainer {
    padding: 3rem 2rem !important;
  }
}

@media only screen and (max-width: 600px) {
  .servicesContainer .MuiGrid-item {
    flex-basis: 100%;
    max-width: 100%;
  }

  .servicesContainer {
    padding: 3rem 2rem !important;
  }
  .browseTitle {
    font-size: 2rem !important;
  }
}
