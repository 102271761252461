@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

.navContainer {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(27, 37, 46, 1) 35%,
    rgba(64, 163, 209, 1) 100%
  );
  padding: 2rem;
  display: flex !important;
  justify-content: space-evenly !important;
}

.navList {
  display: flex;
}

.leftNav {
  display: flex;
  flex-direction: row !important;
}
.rightNav {
  display: flex;
}

.navButton {
  font-family: Rubik !important;
  color: white !important;
  font-size: 0.9rem;
  font-weight: 700;
  opacity: 0.9;
}

.rightNavButtons {
  background-color: #070826 !important;
  font-weight: 600;
  width: 8vw;
  height: 5vh;
  padding: 1.3em;
  margin: 1em !important;
  border-radius: 15px !important;
  font-size: 0.8em;
}

.rightNavButtons:hover {
  color: #040425;
  background-color: #efc734 !important;
  scale: 1.1;
}

.navButton:hover {
  color: #efc734 !important;
}

.otherPageNavbar {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(27, 37, 46, 1) 35%,
    rgba(64, 163, 209, 1) 100%
  );
  padding: 1rem 12rem;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  box-shadow: none;
}

.drawerButton {
  color: rgba(255, 255, 255, 0.682) !important;
}

.drawerList {
  width: 250px;
}

.logoSmall {
  width: 190px !important;
  height: 50px !important;
}

.drawerText {
  color: black !important;
}

.drawerText:hover {
  color: white !important;
}

/*tablet screens*/

@media screen and (min-width: 769px) and (max-width: 1024px) {
}

/* Mobile screens */
@media screen and (max-width: 768px) {
  .otherPageNavbar {
    padding: 1rem !important;
  }
}
