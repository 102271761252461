@import url('https://fonts.googleapis.com/css2?family=Anton&family=Bebas+Neue&display=swap');

.title {
  font-family: Anton !important;
  font-size: 2rem !important;
  margin-bottom: 2rem !important;
}

.budgetBox {
  background-color: #f3f3f7;
  max-height: 11rem !important;
  min-width: 9rem !important;
  padding: 1rem 3rem !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 25px;
}

.budgetNumber {
  font-family: Anton !important;
  font-size: 2.5rem;
  letter-spacing: 2px;
}

.offerButton {
  border-radius: 25px !important;
  padding: 0.6rem 1.5rem !important;
  background-color: #040225 !important;
}
@media only screen and (max-width: 1023px) {
  .detailsContainer {
    margin: 5rem 0 !important;
    width: 100% !important;
    max-height: 83vh !important;
    padding: 18px 4px !important;
  }
  .topDetailContainer {
    flex-direction: column !important;
  }
  .budgetBox {
    margin-top: 1rem !important;
  }
  .browseFiller {
    display: none !important;
  }
}
