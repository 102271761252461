@keyframes colorShift {
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
}

.animatedText {
  background: linear-gradient(90deg, #050425, #213a49, #3f9fcc);
  background-size: 200% 200%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: colorShift 3s infinite;
  display: inline-block;
}

.typographyCustom {
  font-size: 3rem;
  font-weight: bolder !important;
}

@media (max-width: 600px) {
  .container {
    width: 90% !important;
    padding: 3rem 1rem !important;
    margin: 6rem 1rem !important;
  }
  .typographyCustom {
    text-align: center !important;
    font-size: 2rem !important;
  }
  .actionList {
    display: flex !important;
    flex-direction: column !important;
    padding: 1rem 0 !important;
  }
  .actionItem {
    display: flex !important;
    flex-direction: column !important;
    margin-bottom: 0.5rem !important;
  }
  .actionItemTitle {
    margin-bottom: 0.5rem !important;
  }
  .deleteModal {
    width: 75% !important;
  }
}

@media (min-width: 601px) and (max-width: 960px) {
  .container {
    width: 90% !important;
    padding: 3rem 1rem !important;
    margin: 6rem 4rem !important;
  }
  .deleteModal {
    width: 70% !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}
