.taskListContainer {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 1rem;
  max-height: 87vh;
  margin: auto;
  min-width: 23rem;
  max-width: 23rem;
  background-color: #f3f3f7;
}

.taskCard {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  border-radius: 10px;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  background-color: #ffffff !important;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  min-height: 10rem !important;
  cursor: pointer;
}

.taskCard:hover {
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.15);
  transform: translateY(-5px);
}

.taskCardTitle {
  font-size: 1rem !important;
  color: #333;
  font-weight: bold !important;
  margin-bottom: 1rem !important;
}

.taskCardDetail {
  font-size: 0.875rem;
  color: #757575;
  margin-bottom: 0.5rem;
}

.taskCardBudget {
  font-size: 1rem;
  color: #333;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

@media only screen and (max-width: 1023px) {
  .taskListContainer {
    min-width: 19.7rem;
    max-width: 19.7rem;
  }

  .taskCard {
    padding: 1.5rem;
  }
}
