.browseContainer {
  display: flex;
  margin: 2rem 9rem 0;
  min-width: 90% !important;
  max-height: 100vh !important;
  overflow: hidden;
}

/* Small and medium-sized mobile screens */
@media only screen and (max-width: 1023px) {
  .browseContainer {
    margin: 1rem;
  }
}
