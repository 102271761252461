.chatContainer {
  display: flex;
  height: 100vh;
  padding: 1rem;
  background-color: #f5f7fa;
}

.chatList {
  width: 300px;
  overflow: auto;
  background: linear-gradient(
    30deg,
    rgba(64, 163, 209, 1) 35%,
    rgba(27, 37, 46, 1) 100%
  );
  padding: 1rem;
  border-radius: 8px;
  margin-right: 1rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.chatListItem {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
  background-color: transparent;
  transition: background-color 0.3s, transform 0.3s !important;
  border-radius: 8px;
  color: white;
  font-size: 1rem;
}

.chatListItem:hover {
  background-color: #e5bb32d4;
  transform: translateY(-2px);
}

.chatName {
  font-size: 1rem;
  font-weight: bold;
}

.chatDetails {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1) !important;
}

.chatHeader {
  padding: 1rem !important;
  border-bottom: 1px solid #ccc !important;
  display: flex !important;
  align-items: center !important;
}

.messageList {
  flex-grow: 1;
  overflow-y: auto !important;
  padding: 1rem !important;
  display: flex !important;
  flex-direction: column !important;
}

.message {
  display: flex !important;
  align-items: center !important;
  padding: 0.8rem !important;
  margin: 8px 0 !important;
  border-radius: 20px !important;
  max-width: 70% !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1) !important;
}

.messageSenderYou {
  align-self: flex-end !important;
  background-color: #e0f7fa !important;
}

.messageSenderOther {
  align-self: flex-start !important;
  background-color: #f3f3f7 !important;
}

.messageText {
  margin-left: 8px;
  font-size: 0.875rem !important;
}

.messageInput {
  display: flex !important;
  padding: 10px;
  border-top: 1px solid #ccc !important;
  background-color: #fff !important;
  align-items: center !important;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1) !important;
}

.messageInputField {
  flex-grow: 1 !important;
  border-radius: 20px !important;
  padding: 10px !important;
  border: 1px solid #ccc !important;
  margin-right: 8px !important;
}

.sendButton {
  background-color: #1b252e !important;
  color: white;
  padding: 10px 20px !important;
  border-radius: 20px !important;
  border: none !important;
  cursor: pointer;
  display: flex !important;
  align-items: center !important;
}

.sendButton:hover {
  background-color: #333 !important;
}

.selectChatMessage {
  text-align: center !important;
  margin-top: 20px !important;
  color: #aaa !important;
}

.chatAvatar {
  background-color: #1b252e !important;
  color: white;
  margin-right: 8px !important;
}
